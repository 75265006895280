<template>
    <v-app>
      <v-card class="bgs rounded-0 d-flex justify-center" flat width="100%" height="100%">
        <v-container>
            <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("Taoify 用户协议") }}
        </h2>
        <h3 class="titlee">{{ $t("1、服务条款约定") }}</h3>
<p class="content">
  {{ $t("本协议系 Taoify 由对 Taoify 的使用及相关服务所订立的有效合约。用户注册 Taoify 即代表接受本条款的约束，并自注册成功之时即成为本合约一方。使用 Taoify 的任何服务即表示接受本协议的全部条款。本协议适用于任何 Taoify 相关服务，包括本协议期限内的用户所使用的各项服务和软件的升级和更新。") }}
</p>

<h3 class="titlee">{{ $t("2、服务内容") }}</h3>
<p class="content">
  {{ $t("向用户提供 Taoify 官网所展示的电商建站服务、相关的技术支持以及网络支持等服务。") }}
</p>

<h3 class="titlee">{{ $t("3、服务费用") }}</h3>
<p class="content">
  {{ $t("服务费用将根据 Taoify 官网上现有时效的价格计算并在购买页面列明，您需在约定的支付期限内通过约定的支付手段支付相应的服务费用。在您未按照约定支付全部服务费用之前，Taoify 将保留不向您提供技术支持和/或服务，以及终止技术支持和/或服务的权利。您完全理解 Taoify 价格体系中所有的赠送服务项目，以及 Taoify 提供的优惠活动。") }}
</p>

<h3 class="titlee">{{ $t("4、付费服务终止或续费") }}</h3>
<p class="content">
  {{ $t("Taoify 付费服务的授权期限到期后，如果未能及时购买相应服务续费，用户服务自动终止。Taoify 将执行自动程序监控您当前网站的时间再2天-16天内的订单履约情况，不同履约率将触发不同级别的警告信，请您再收到警告信之后，您承诺将改进发货效率以保障买家的利益，若长时间没有整改时，Taoify 有权利终止服务。") }}
</p>

<h3 class="titlee">{{ $t("5、使用守则") }}</h3>
<p class="content">
  {{ $t("为有效保障您使用 Taoify 的合法权益，您理解并同意接受以下规则：您了解并同意 Taoify 单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示为准，您同意并遵照服务规则是您使用 Taoify 平台的前提。Taoify 会以短信或邮件通知您服务进展情况以及提示您进行下一步操作，但 Taoify 不保证您能够收到或者及时收到该短信或邮件，且不对此承担任何后果。因此，在服务过程中您应当及时登录到本网站查看和进行相关操作。因您没有及时查看和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，Taoify 不付任何责任。在您使用 Taoify 时，Taoify 有权依照相应的服务收费介绍、订单或者相关协议向您收取服务费用。Taoify 拥有制定以及调整服务资费的权利，具体服务资费以您使用 Taoify 时本网站上所列之收费方式公告或您与 Taoify 达成的其他书面协议为准。当您违反 Taoify 条款的情况下，Taoify 有权拒绝继续为您提供任何服务。") }}
</p>

<h3 class="titlee">{{ $t("6、您的权利和义务") }}</h3>
<ol class="content">
  <li>{{ $t("您有权利拥有自己在 Taoify 的用户名和密码，并有权利使用自己的用户名和密码随时登录 Taoify。") }}</li>
  <li>{{ $t("您有权利享受 Taoify 提供的服务，并有权利在接受 Taoify 提供的服务时获得 Taoify 的技术支持，服务内容详见 Taoify 相关产品介绍。") }}</li>
  <li>{{ $t("您保证不会利用技术或其他手段破坏及扰乱 Taoify 及 Taoify 其他客户的网站和服务。") }}</li>
  <li>{{ $t("您应尊重 Taoify 及其他第三方的知识产权和其他合法权利，Taoify 保留您侵犯 Taoify 合法权益时终止向您提供服务并不退还任何款项的权利。") }}</li>
  <li>{{ $t("对由于您向 Taoify 提供的联络方式有误以及您用于接受 Taoify 邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到 Taoify 的相关通知而导致的后果和损失。") }}</li>
  <li>{{ $t("您保证您使用 Taoify 服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用 Taoify 提供的服务进行存储、发布、传播妨碍互联网运行安全的信息侵害他人合法权益的信息或其他有损于社会秩序、社会治安、公共道德的信息或内容。") }}</li>
  <li>{{ $t("您同时承诺不得为他人发布上述不符合国家规定或 Taoify 条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等。您承认 Taoify 有权在您违反上述约定时有权终止向您提供服务并不予退还任何款项，因您上述行为给 Taoify 造成损失的，您应予赔偿。") }}</li>
  <li>{{ $t("您保证您不会进行任何滥用 Taoify 资源的行为，包括但不仅限于任何与黑客攻击相关的行为、做外链图床等。") }}</li>
  <li>{{ $t("您有权利要求 Taoify 为您支付的服务费用开取正规发票，但发票中的金额不包括充值活动期间 Taoify 赠送的部分。") }}</li>
  <li>{{ $t("您承诺不上传或者销售中国法律法规严禁出口的商品及销售目标国法律法规严禁进口的商品。") }}</li>
</ol>

<h3 class="titlee">{{ $t("7、Taoify 的权利和义务") }}</h3>
<ol class="content">
  <li>{{ $t("Taoify 应根据您选择的服务以及交纳款项的情况向您提供合格的服务。") }}</li>
  <li>{{ $t("Taoify 承诺对您的资料采取对外保密措施，不向第三方披露您资料，不授权第三方使用您资料，除非：用户的行为违反 Taoify 规定、通告、法律或公序良俗时，为保护 Taoify 及其他用户等第三方的权利和财产利益，您的资料将被用作为相应凭据；依据法律法规的规定应当提供；制作统计资料、进行市场调查和数据分析时，会对您的资料信息进行非特定化处理后作为相应的材料进行提供；行政、司法等职权部门要求 Taoify 提供；Taoify 解决举报事件、提起诉讼而提交的；Taoify 为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；您同意 Taoify 向第三方提供。") }}</li>
  <li>{{ $t("Taoify 网站有权使用您的资料和信息。") }}</li>
  <li>{{ $t("Taoify 有权利对您进行审查并决定是否接受您成为 Taoify 用户。") }}</li>
  <li>{{ $t("Taoify 保留在您违反国家、地方法律法规规定或违反本条款的情况下终止为您提供服务的权利，并且在任何情况下，Taoify 对任何间接、偶然、特殊及继起的损害不负责任。") }}</li>
  <li>{{ $t("Taoify 保留在您网站没有经过备案就开放的情况下，配合国家相关部门关闭网站的权利。") }}</li>
  <li>{{ $t("Taoify 保留在您滥用系统资源的情况下，强制关停及冻结账号的权利。") }}</li>
</ol>

<h3 class="titlee">{{ $t("8、隐私及其他个人信息的保护") }}</h3>
<p class="content">
  {{ $t("一旦您同意本协议或使用 Taoify，您即同意 Taoify 按照以下条款来使用和披露您的个人信息。用户名和密码：在您注册为 Taoify 用户时，我们会要求您设置用户名和密码来识别您的身份。您仅可通过您设置的密码来使用该账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。该账户和密码因任何原因受到潜在或现实危险时，您应该立即和 Taoify 取得联系，在 Taoify 采取行动前，Taoify 对此不负任何责任。") }}
</p>
<p class="content">
  {{ $t("注册信息：您注册账户时应向 Taoify 提供您的真实姓名、地址、电话号码和电子邮件地址。为有针对性地向您提供服务，您了解并同意 Taoify 将通过您的电子邮件地址通知您这些信息。") }}
</p>
<p class="content">
  {{ $t("第三方支付接口信息：若 Taoify 所提供的服务需要您提供您第三方支付平台接口API信息, 包括但不限于微信、快钱、财付通或支付宝接口信息，在您提供相应信息后，Taoify 将严格履行相关保密约定。") }}
</p>
<p class="content">
  {{ $t("外部链接：本网站含有到其他网站的链接，但 Taoify 对其他网站的隐私保护措施不负任何责任。Taoify 可能在任何需要的时候增加合作伙伴或共用品牌的网站。") }}
</p>
<p class="content">
  {{ $t("安全：Taoify 仅按现有技术提供相应的安全措施来使 Taoify 掌握的信息不丢失，不被滥用和伪造。这些安全措施包括向备份数据和对用户密码加密。尽管有这些安全措施，但 Taoify 不保证这些信息的绝对安全。") }}
</p>

<h3 class="titlee">{{ $t("9、系统中断或故障") }}</h3>
<p class="content">
  {{ $t("系统因下列状况无法正常运作，使您无法使用各项服务时，Taoify 不承担损害赔偿责任，该状况包括但不限于：Taoify 官网公告系统停机维护期间；电信设备出现故障不能进行数据传输的；因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成 Taoify 系统障碍不能执行业务的；由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。") }}
</p>

<h3 class="titlee">{{ $t("10、责任范围及责任限制") }}</h3>
<p class="content">
  {{ $t("Taoify 仅对本协议中列明的责任承担范围负责。Taoify 用户信息是由您本人自行提供的，Taoify 无法保证该信息之准确、及时和完整。Taoify 不提供任何形式的保证，包括但不限于以下事项：Taoify 符合您的需求；Taoify 不受干扰、及时提供或免于出错；Taoify 的用户创建网站，所提供内容由创建用户自行负责；基于 Taoify 开发的第三方应用，其行为、內容、资料或数据等由第三方负责。对于第三方引起或与第三方有关，对您造成的任何已知和未知的損害或赔偿，Taoify 概不负责；在法律允许的情况下，Taoify 对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任；除本协议另有规定外，在任何情况下，Taoify 对本协议所承担的违约赔偿责任总额不超过向您收取的当次服务费用总额。") }}
</p>

<h3 class="titlee">{{ $t("11、商标与知识产权保护") }}</h3>
<p class="content">
  {{ $t("本网站 Taoify 上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站界面的安排、网页设计，均由本公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业机密等。除非经本公司官方书面同意，否则任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。") }}
</p>

<h3 class="titlee">{{ $t("12、条款修订") }}</h3>
<p class="content">
  {{ $t("Taoify 有权随时对本合约的条款进行修订，并在修订生效日前一个工作日更新在 Taoify 网站。Taoify 有权对服务定价进行修订，并在修订生效日前一个月通告给所有用户。修订的条款始终公开在 Taoify 相关网站。") }}
</p>

<h3 class="titlee">{{ $t("13、争议解决") }}</h3>
<p class="content">
  {{ $t("如果就本协议的解释或执行发生争议，双方应首先力争通过友好协商解决该争议。如果在一方向其他方送达要求开始协商的书面通知后六十天内未能通过协商解决争议，那么任何一方均可将争议提交至中国国际经济贸易仲裁委员会上海分会，按照其届时有效的规则进行仲裁。仲裁裁决是终局的，对各方均有约束力，不可上诉。仲裁费用由败诉方承担，除非仲裁裁决另有规定。当任何争议发生时以及在对任何争议进行仲裁时，除争议事项外，各方应继续行使各自在本协议项下的其他权利，履行各自在本协议项下的其他义务。") }}
</p>

          <p class="content">
            © Taoify 2024 
          </p>
        </v-container>
      </v-card>
    </v-app>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .titlee {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    line-height: 25px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .content {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
  }
  li {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
    margin-left: 20px;
    list-style: initial;
  }
  </style>